import React from "react";

import { Helmet } from "react-helmet";
import Banner from "../blocs/Banner";
import Header from "../components/headers/header";
import Footer from "../blocs/Footer";

export default function Index() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Contact validation - Fissuration.fr</title>
        <link
          rel="canonical"
          href="https://www.expertise-fissuration.fr/contact-validation/"
        />
      </Helmet>
      <header>
        <Banner />
        <Header />
      </header>
      <main>
        <div className="bg-white">
          <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">Merci pour votre demande !</span>
              <span className="block">
                Nous allons revenir vers vous rapidement.
              </span>
            </h2>
            <div className="mt-8 flex justify-center">
              <p>
                <a className="text-blue-500" href="/">
                  Revenir à l'accueil
                </a>
              </p>
            </div>
          </div>
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}
